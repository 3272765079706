"use client";
import { FormattedMessage } from "react-intl";

import styles from "./Error.module.css";
import messages from "./messages";

interface Props {
  id: string;
  reasonCode: keyof typeof messages;
  url?: string;
  transId?: string;
}

function InLineError({ id, reasonCode, url, transId }: Props) {
  return (
    <div id={`${id}InlineError`} className={styles["inline-error"]}>
      {messages[reasonCode] && (
        <>
          <div className={styles["inline-error__icon"]}>
            <i
              className="fa fa-fw fa-exclamation-triangle"
              aria-hidden="true"
            ></i>
          </div>
          <div className={styles["inline-error__content"]}>
            <FormattedMessage
              {...messages[reasonCode]}
              values={{
                transId,
                a: (chunks) => (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer external"
                  >
                    {chunks}
                  </a>
                ),
                div: (chunks) => <div>{chunks}</div>,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default InLineError;
