import { defineMessages } from 'react-intl';

export default defineMessages({
    failedLogonAttempt: {
        id: 'Error.failedLogonAttempt',
        defaultMessage: 'The log on details entered are incorrect. After too many attempts your account will be temporarily locked.<div>Please review the details entered or contact our <a>Customer Service Centre</a> for assistance.</div>',
    },
    invalidClientIPAddress: {
        id: 'Error.invalidClientIPAddress',
        defaultMessage: 'Your IP address is not within the allowed range specified by your Organisation.<div>Please try again. If this error persists, please contact your <a>ANZ Customer Service Centre</a>.</div>',
    },
    adkLogonErrorNotCompleted: {
        id: 'Error.adkLogonErrorNotCompleted',
        defaultMessage: '<title>Log On Request Expired</title><div>You have not completed the ANZ Digital Key request within the allowed time. Log On Reference: {transId}</div>',
    },
    adkLogonErrorNotAccepted: {
        id: 'Error.adkLogonErrorNotAccepted',
        defaultMessage: '<title>Log On Declined</title><div>You have not accepted the request to log on via ANZ Digital Key. Log On Reference: {transId}</div>',
    },
    adkLogonErrorNotAvailable: {
        id: 'Error.adkLogonErrorNotAvailable',
        defaultMessage: 'The log on method you have selected is not available to you at this time.<div>If you have recently completed the ANZ Digital Key Activation process, please re-enter your password and click Log On once you receive an activation confirmation email.</div><div>Alternatively, please select a different Log On Method or click ANZ Digital Key Activation.</div>',
    },
    adkLogonErrorOccurred: {
        id: 'Error.adkLogonErrorOccurred',
        defaultMessage: '<title>An error occurred.</title><div>Please try again. If this error persists, please contact your <a>ANZ Customer Service Centre</a>.</div>',
    },
    tokenLogonErrorLocked: {
        id: 'Error.tokenLogonErrorLocked',
        defaultMessage: '<title>Your token has been locked.</title><div>Please try again. If this error persists, please contact your <a>ANZ Customer Service Centre</a>.</div>',
    },
    tokenLogonErrorNotAvailable: {
        id: 'Error.tokenLogonErrorNotAvailable',
        defaultMessage: 'The log on details entered are incorrect. After too many attempts your account will be temporarily locked.<div>Please review the details entered or contact our <a>Customer Service Centre</a> for assistance.</div>',
    },
    tokenLogonError: {
        id: 'Error.tokenLogonError',
        defaultMessage: 'The log on details entered are incorrect. After too many attempts your account will be temporarily locked.<div>Please review the details entered or contact our <a>Customer Service Centre</a> for assistance.</div>',
    },
    tokenLogonErrorOccurred: {
        id: 'Error.tokenLogonErrorOccurred',
        defaultMessage: '<title>An error occurred.</title><div>Please try again. If this error persists, please contact your <a>ANZ Customer Service Centre</a>.</div>',
    },
});