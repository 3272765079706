"use client";

import { ContentPanel } from "@omni/panel";
import { Heading } from "@omni/heading";

import styles from "./Error.module.css";

interface Props {
  heading: string;
  children: React.ReactNode;
}

export default function Error({ heading, children }: Props) {
  return (
    <ContentPanel
      style={{
        position: "static",
        background: "none",
        marginBottom: "3em",
        display: "flex",
        flexDirection: "column",
      }}
      className={styles.content}
    >
      <Heading
        text={heading}
        level="1"
        style={{
          color: "#004165",
          fontWeight: 500,
          lineHeight: "2em",
          borderBottom: "2px solid #004165",
        }}
      />
      <div style={{ width: "100%", margin: "2em 0 1em 0" }}>{children}</div>
    </ContentPanel>
  );
}
