"use client";
import { FormattedMessage } from "react-intl";

import { InlineNotification } from "@omni/notification";

import styles from "./Error.module.css";
import messages from "./messages";

interface Props {
  id: string;
  reasonCode: keyof typeof messages;
  url?: string;
  transId?: string;
}

function ProminentError({ id, reasonCode, url, transId }: Props) {
  return (
    <div
      id={`${id}ProminentError`}
      className={`${styles["inline-error"]} ${styles["inline-error-prominent"]}`}
    >
      {messages[reasonCode] && (
        <InlineNotification type="error">
          <FormattedMessage
            {...messages[reasonCode]}
            values={{
              transId,
              a: (chunks) => (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer external"
                >
                  {chunks}
                </a>
              ),
              div: (chunks) => <div>{chunks}</div>,
              title: (chunks) => (
                <div className={styles["inline-error__title"]}>{chunks}</div>
              ),
            }}
          />
        </InlineNotification>
      )}
    </div>
  );
}

export default ProminentError;
