/*
  -IMPORTANT-
  Need to keep this page with minimalistic/light logic as possible without connecting to global state/cache storage -
  and without any side effect handling as this will be rendered during unhandled exceptions on client code
*/
"use client";

import { IntlProvider } from "react-intl";

import { ThemeProvider } from "@omni/theme";

import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Panel } from "@omni/panel";
import { Error } from "@/components/Error";

import messages from "../content/compiledLocales/en.json";

import styles from "./page.module.css";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  // console.error("Global error : ", error);
  return (
    <html lang="en">
      <title>ANZ Log On</title>
      <body>
        <IntlProvider locale="en" messages={messages}>
          <ThemeProvider theme="external">
            <>
              <Header link={`${process.env.NEXT_PUBLIC_BASE_PATH}/` || "/"} />
              <Panel
                className={styles.content}
                style={{
                  background: "none",
                }}
              >
                <Error heading="Sorry, something went wrong">
                  <p>
                    {`To log on to a digital service, please visit `}
                    <a
                      id="logout-digital-service-status-page"
                      href="https://www.anz.com/digitalservices"
                      target="_blank"
                      rel="noopener noreferrer external"
                    >
                      ANZ Digital Services
                    </a>
                    {` and choose the service you would like to access.`}
                    <br />
                    <br />
                    {`If this issue persists, please contact our `}
                    <a
                      href="https://www.anz.com/servicecentres"
                      target="_blank"
                      rel="noopener noreferrer external"
                    >
                      Customer Service Centre
                    </a>
                  </p>
                </Error>
              </Panel>
              <Footer
                isMandatoryFieldsShown={false}
                digitalServiceStatusLink="https://status.online.anz.com"
                securityDeviceUserGuideLink="https://www.anz.com/deviceguide"
                onlineResourcesHelpLink="https://www.anz.com/onlineresources"
                bankingSafeLink="https://www.anz.com/onlinesecurity"
              />
            </>
          </ThemeProvider>
        </IntlProvider>
      </body>
    </html>
  );
}
